<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neue Region">
      <region-form
        :region="region"
        submit-label="Neue Region anlegen"
        :form-submitted="saveRegion"
        mode="new"
      ></region-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionForm from "@/components/forms/RegionForm"
import RegionsRepository from "@/repositories/super_admin/regions_repository.js"

export default {
  metaInfo: {
    title: "Neue Region"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionForm
  },
  data() {
    return {
      region: {
        name: ""
      }
    }
  },
  methods: {
    async saveRegion(region) {
      await RegionsRepository.create(region)
      this.$router.push({ name: "RegionsPage" })
    }
  }
}
</script>
